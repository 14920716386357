/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  h4{
    font-family: $fontTwo;
    font-size: rem(14);
    font-weight: 600;
    a{
      background-color: transparent;
      border: solid 1px $link-color;
      display: block;
      text-align: center;
      color: $link-color;
      padding: 10px 20px 10px 10px;
      margin-bottom: 0px;
      line-height: 1.3;
      text-decoration: none;
      transition: background-color .2s;
      transition: color .2s;
      &:hover{
        background-color: $link-color;
        color: white;
      }
    }
  }
  .goToLink,
  .emailLink{
    &:before{
      display:none;
    }
    &:after{
      color: $accent-color;
      content: "\f105"; // Right Arrow
      font-family: FontAwesome;
      display:inline-block;
      line-height: .8em;
      margin:0 0 0 6px;
      padding:0;
      position: relative;
      transition: color .2s;
    }
    &:hover:after {
      color: #fff;
    }
  }
  .emailLink {
    &:after{
      content: "\f003"; // envelope-o
    }
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}

// CUSTOM LINKS
.fill-link {
  .linkElement h4 a {
    background-color: $link-color;
    border: none;
    color: #fff;
    font-size: 14px;
    transition: all .2s;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0;
    &:after {
      height: 100%;
      background: rgba(0,0,0,.1);
      position: absolute;
      width: 25px;
      top: 0px;
      right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      transition: all .2s;
      justify-content: flex-end;
      padding-right: 20px;
    }
    &:hover {
      &:after {
        transition: all .2s;
        width: calc(100% - 20px);
      }
    }
  }
}

.highlight-link {
  .linkElement h4 a {
  background-color: #F2F3F4;
  border: 1px solid #e6e4e4;
  color: #181824;
  padding: 10px 35px 10px 15px;
  font-size: 18px;
  font-family: $fontTwo;
  transition: all .2s;
  line-height: .85;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: none;
  text-align: left;
  &:before {
    content: '';
    width: 5px;
    height: calc(100% + 2px);
    background-color: $link-color;
    position: absolute;
    left: 0;
    top: -1px;
    display: block;
  }
  &:after {
    height: 100%;
    position: absolute;
    width: 40px;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $link-color;
    transition: all .2s;
    justify-content: center;
  }
  .first-word {
    font-size: 32px;
    font-family: $fontOne;
    font-weight: 600;
    margin: 0px 5px;
  }
  &:hover {
    &:after {
      transition: all .2s;
      color: $link-color;
      margin-right: -10px;
    }
  }
  }
}
