.player-highlight-container {
  display: flex;
  overflow: hidden;
  height: 200px;
  border: 1px solid #C2C6CA;
  margin-bottom: 15px;
  .left-column-container {
    display: flex;
    width: 60%;
    justify-content: space-around;
    background-color: #fff;
    .player-photo-section {
      width: 200px;
      overflow: hidden;
      max-height: 200px;
      .playerPhoto {
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 1;
        position: absolute;
        max-width: 200px;
        top: 0px;
        max-height: 199px;
        .edit_mode & {
          top: inherit;
        }
      }
      .highlight-team-logo {
        position: absolute;
        display: block;
        // margin-top: -200px;
        z-index: 0;
        height: 200px;
        width: 200px;
        background-size: 130%;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: .3;
        filter: blur(1px);
        top: 0px;
        .edit_mode & {
          top: inherit;
        }
      }
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
    .player-info-section {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 30px;
      line-height: 1;
      .largePlayerText {
        font-size: 58px;
      }
      .playerHighlightName {
        font-family: $fontTwo;
        font-size: 28px;
        color: $pop-color;
        line-height: 1;
        font-weight: 500;
        margin: 10px 0px 5px 0px;
        strong {
          font-size: 36px;
          font-weight: 500;
        }
      }
      .playerHighlightPosition {
        margin-bottom: 0px;
        line-height: 1;
        font-size: 14px;
      }
    }
  }
  .right-column-container {
    background-color: #EEEEEE;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.vertical-stats {
    .right-column-container {
      table {
        display: flex;
        justify-content: center;
        border: none;
        tbody {
          tr {
            display: flex;
            align-items: center;
            td {
              display: inline-block;
              border: none;
              color: $link-color;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
            }
            td:first-of-type  {
                font-family:  $fontOne !important;
                font-size: 24px;
                color: #181824;
                font-weight: 600;
                text-align: left;
                padding-right: 10px;
                line-height: 1.5;
            }
          }
        }
      }
    }
  }
  &.horizontal-stats {
    .right-column-container {
      table {
        border: none;
        // display: flex;
        // justify-content: center;
        tbody {
          tr {
            display: flex;
            justify-content: space-around;
            align-items: center;
            td {
              font-family: $fontOne !important;
              border: none;
              color: $link-color;
              font-size: 20px;
              font-weight: 600;
              line-height: 1;
              width: 55px;
              text-align: center;
              width: 90px;
              strong {
                font-size: 32px !important;
                width: 55px;
                font-weight: 600;
                span {
                  font-size: 32px !important;
                  width: 55px;
                  font-weight: 600;
                }
              }
            }
          }
            tr:first-of-type  {
              td {
                font-family:  $fontOne !important;
                font-size: 48px;
                color: #181824;
                font-weight: 700;
                strong {
                  font-size: 70px !important;
                  width: 55px;
                  font-weight: 700;
                  span {
                    font-size: 70px !important;
                    width: 55px;
                    font-weight: 700;
                  }
                }
              }
            }
        }
      }
    }
  }
  .has-mobile-nav & {
    height: auto;
    flex-flow: column;
    .left-column-container {
      width: 100%;
      height: 150px;
      flex-flow: row;
      justify-content: space-between;
      .player-photo-section {
        width: 150px;
        max-width: 150px;
        .playerPhoto {
          max-width: 150px;
          top: inherit;
          max-height: 150px;
        }
        .highlight-team-logo {
          height: 150px;
          width: 150px;
          top: inherit;
        }
      }
      .player-info-section {
        width: 50%;
        overflow: hidden;
        padding: 20px;
        .largePlayerText {
          font-size: 32px;
          line-height: 1;
        }
        .playerHighlightName {
          font-size: 16px;
          margin: 3px 0px 3px 0px;
          strong {
            font-size: 16px;
          }
        }
        .playerHighlightPosition {
          font-size: 10px;
        }
      }
    }
    .right-column-container {
      width: 100%;
      min-height: 200px;
    }
  }
}
