// Player Profile Styles
.user_mode {

	.playerProfile {
		margin-bottom: 0;
    position: relative;

    .column {
      margin: 0 15px;
      padding: 0;
      width: calc(100% - 30px) !important;
      border: solid 1px $gray;
      border-bottom: none;
      background: $light;

      &:after {
        content: '';
        display: none;
        position: absolute;
        height: 1px;
        width: 100%;
        left: 0;
        background: $gray;
        bottom: 0;
        @media only screen and (max-width: 1024px){
          bottom: -5px;
          display: none;
        }
      }

    }

		.rowOne,
		.rowTwo {
      position: relative;
      width: calc(50% - 3px);
      display: inline-block;
      @media only screen and (max-width: 1024px){ width: 100%; }
    }

    .rowTwo {
      padding-left: 5%;
      height: 100%;
      position: absolute;
      @media only screen and (max-width: 1024px){ position: relative; }
    }

    .rowOne { background: #ffffff; }

    @media only screen and (max-width: 1024px){
      .rowOne {
        margin-bottom: 15px;
        position: relative;

        &:after {
          content: '';
          display: none;
          position: absolute;
          height: 1px;
          width: 100%;
          left: 0;
          background: $gray;
          bottom: 0;
          @media only screen and (max-width: 1024px){
            display: block;
          }
        }

      }
    }

		.layoutInner {
			border-bottom: solid 3px $accent-color;
			max-width: 855px;
		}

		.pageEl {
			display: inline-block;

			&:last-of-type .pageElement { margin-right: 0; }

			.pageElement { margin-bottom: 0; }

		}

		.playerPhoto {
			width: 50%;
      padding-right: 15px;
      vertical-align:text-bottom;

			.heroPhotoElement {
				position: relative;
				text-align: center;
        padding-top: 15px;

        img { margin-bottom: -1px; }

			}

		}

		.statsOne {
			width: 50%;
      padding-bottom: 10px;

			.textBlockElement {

				h3 {
					color: $link-color;
					margin-bottom: 10px;

					span {
						font-size: 1rem;
					}

				}

				.text {
					margin-top: 0;
          @media only screen and (max-width: 640px){ padding-right: 10px; }

					.number {
						color: $link-color;
						font-size: 4vw;
						font-weight: 700;
						margin-bottom: 0;
            line-height: normal;
            font-family: $fontOne;
					}

					.name {
						color: $pop-color;
						font-weight: 500;
						font-size: 2vw;
            line-height: 2vw;
						line-height: normal;
						margin-bottom: 10px;

            strong {
              font-size: 2.5vw;
              line-height: 2.5vw;
            }

            @media only screen and (max-width: 1024px){
              font-size: 4vw;
              line-height: 4vw;

              strong {
                font-size: 5vw;
                line-height: 5vw;
              }
            }

            @media only screen and (max-width: 768px){
              font-size: 5vw;
              line-height: 5vw;

              strong {
                font-size: 6vw;
                line-height: 6vw;
              }

            }

            a { color: $pop-color; }

					}

					.position {
						font-weight: 500;
						margin-top: -9px;
            line-height: 18px;
            font-style: italic;
            @media only screen and (max-width: 640px){
              font-size: 14px;
              line-height: 14px;
            }

						u {
							color: $accent-color;
							text-decoration: none;
						}

					}


				}

			}

		}

		.statsTwo {
			width: 75%;
      padding-bottom: 10px;
      @media only screen and (max-width: 1024px){ width: 60%; }

      h2 {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          display: inline-block;
          background: url('../images/icons/soccer-ball.svg') no-repeat;
          background-position: top center;
          background-size: auto 100%;
          width: 100%;
          height: 100%;
          bottom: 100%;
          left: 0;
          @media only screen and (max-width: 1300px){
            position: relative;
            width: 50px;
            height: 35px;
            bottom: 0;
          }
        }

      }

		}

		.playerLogo {
			width: 25%;
      padding-bottom: 10px;
      height: 100%;

      .heroPhotoElement {
        height: 100%;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1024px){ display: block; }
      }

      @media only screen and (max-width: 1024px){
        width: 40%;

        img {
          max-height: 100px;
          width: auto;
          max-width: 100px;
        }

      }

		}

	}

}


.games-played {
  margin: 0;
  text-align: center;

  @media only screen and (max-width: 640px){
    .text {
      h2 {
        span:first-of-type {
          font-size: 30px !important;
        }
      }
    }
  }

  > div {
    display: block;
    width: 100%;
    text-align: center;
  }

  p {
    margin-bottom: 0;

    &.stats-minor,
    &.stats-major {
      display: inline-block;
      padding: 0;
      margin: 0;
      font-style: italic;

      &:after { display: none; }

    }

    &.stats-minor {
      margin-right: 5px;
      margin-bottom: 2px;
    }

    &.stats-major {
      font-size: 1em;
      text-align: left;
      font-size: 15px;

      strong { color: $pop-color; }

    }
  }
}


// Player Profile Widget
.more-stats {

  .statTable {
    margin-top: 0;
  }

  .widgetHierarchy.widget-roster-player {

    .player_photo { display: none; }

    ul li.lastLi,
    .leader-highlight {
      background: transparent;

      h4,
      .leader-highlight {
        padding: 0;
      }

      h4 {
        font-family: $fontTwo;
        display: none;

        span { font-style: italic; }

      }

      .leader-highlight {
        height: 0;
      }

    }

  }

}

.player-search-page {
  #panelTwo {
    padding: 15px !important; 
    background: #fff !important;
    margin-bottom: 30px;
  }
}
