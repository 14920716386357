#megaFooter {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-bottom: 60px;
	padding-top: 30px;
	padding-right: 10px;
	.page-manager-visible & {
		padding-bottom: 60px;
		padding-top: 30px;
	}
	.heroPhotoElement {
		max-width: 200px;
		min-width: 200px;
	}
	.pageElement {
		line-height: 1.5rem;
		h3 {
			color: $pop-color;
			font-family: $fontTwo;
			font-size: 13px;
		}
		p {
			margin: 0;
			a {
				color: #fff;
				text-decoration: none;
				transition: .2s ease-in;
				font-size: 12px;
				letter-spacing: .05rem;
				line-height: 1.5
			}
		}
	}
	@include lg-992-max {
		flex-direction: row;
	}
	@include md-768-max {
		flex-direction: column;
		.textBlockElement {
			display: none;
		}
	}
}
