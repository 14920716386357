#siteContainer {
  &, #siteContainer.container-fluid {
    // padding: 0 0 15px;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: $light;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .home.newsPage &,
  .home.newsPage #siteContainer.container-fluid  {
    padding-top: 15px;
  }

  .home &,
  .home #siteContainer.container-fluid  {
    max-width: 1400px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  @media only screen and (min-width: 1025px) {
    #displayBodyHeader:not(.displayBodyHeader-image) {
      margin-left: 15px;
    }
  } 

  #displayBodyHeader:not(.displayBodyHeader-image) {
    padding: 5px 15px;
    font-size: 2.25em;
    font-weight: 600;
    text-transform: initial;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .newsPage & {
    #displayBodyHeader:not(.displayBodyHeader-image) { display: none; }
  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}
