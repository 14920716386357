#showcase {
	height: 50px;
	background: $accent-color;
	border-bottom: solid 1px $coal;
}

// Showcase
#panelThree,
.showcaseMenu {

  .showcase-title {

    .pageElement.textBlockElement {
      padding: 0;
      margin: 0 0 5px;
      background: transparent;
      border-bottom: none;
      border: none;

      h3 { color: $accent-color; }

    }

  }

  .showcase {
		h3 {
			padding-bottom: 5px;
			margin-top: -5px;
		}
		.has-main-nav & {
			max-width: 300px;
		}

    .showcase-tabs {

      li {
        float: left;
        list-style: none;
        position: relative;
				border-right: solid 1px #808587;

				&:last-of-type { border-right: none; }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
        }

        &.slick-active:after { background-color: $league-two; }

        button {
          background-color: $dark;
          border: none;
          border-radius: 0;
          color: #fff;
          text-transform: uppercase;
					// padding: 10px;
					padding: 8px;
          box-sizing: border-box;
          width: 100%;
          font-size: 16px;
          font-family: $fontOne;
          transition: all $transition-standard;

          &:hover {
            cursor: pointer;
            background-color: #808587;
          }

          &:focus { outline: 0; }

        }

      }

    }

    .column {
      padding-right: 0;
			padding-left: 0;
      background: white;
      border: solid 1px $gray;
      float: left;
      width: 100%;
    }

    .pageEl {
  		margin: 0 15px;
      padding-top: 14px;
      background: #fff;
  		position: relative;
      border-bottom: solid 1px $gray;
    }

    .slick-slide {

      .pageEl:nth-of-type(10) { border-bottom: none; }
      .pageEl:last-of-type { border-bottom: none; }

    }

    .pageElement {
      background: transparent;
      border: none;

  		&.textBlockElement {
  			margin: 0;
  			padding: 15px 0 0;
  			position: relative;

  			.text {
  				margin-top: 0;
  				position: absolute;
  				width: 60%;
  				top: 15px;

  				h1, h2, h3, h4 {
  					text-transform: uppercase;
  					line-height: normal;
  				}

  				h1 {
  					color: $accent-color;
  					// font-size: 2rem;
						line-height: 1.5rem;
						//new addition AUG 2018
						word-wrap: normal;
						max-width: 155px;
						font-size: 1.5rem;

  				}

  				h2 {
  					font-size: 1rem;
  					color: $league-two;
  				}

  				h3 {
  					color: $link-color;
  					margin-bottom: 0;
						// font-size: 1.25rem;
						//NEW 
						font-size: 1.0rem;
  				}

  				h4 {
  					color: $coal;
  					font-size: 1.5rem;
  					margin-top: -8px;
  				}

					p img {
						display: inline-block;
						max-width: 40px;
						margin-top: 7px;
						transition: opacity $transition-standard;

						&:hover { opacity: .75; }

					}

  			}

  			.rightTextImage {
  				margin: 0;
          min-height: 120px !important;
          width: 180px !important;

  				img {
  					filter: grayscale(100%);
  					transition: $transition-standard;
  					height: auto;
  					width: auto;
  					max-width: 120px;
            margin: 0;
            float: right;
  				}

  			}

  			&:hover {

  				.rightTextImage img { filter: grayscale(0%); }

  			}

  		}

    }

  }

}

.showcaseMenu .showcase .pageElement.textBlockElement .rightTextImage img { filter: grayscale(0); }


// Showcase Dropdown Menu

.edit_mode {
	.showcaseMenu {
		.show-mobile {
			display: none;
			@media only screen and (max-width: 1024px){ display: block; }
		}
	}
}

.showcaseMenuWrap {
	@include displayCenter($maxWidth);
	top: 0;
  z-index: 9999;

	@media only screen and (max-width: 1023px){ margin-top: 44px; }

}

.showcaseMenu {
	background: white;
	width: 100%;
	position: absolute;
	display: none;

	@media only screen and (min-width: 1024px){ display: none !important; }

}

// Mobile Showcase
.mobileShowcaseBtn {
	position: absolute;
  height: 44px;
  float: right;
	display: none;
	line-height: 44px;
	font-size: 12px;
	top: 0;
  right: 15px;
	color: #f1f1f1;
	transition: color $transition-standard;
	font-family: $fontTwo;

	.slide-nav-open & { display: none !important; }

	&:after {
		content: '\f107';
		font-family: FontAwesome;
		margin-left: 5px;
		transition: content $transition-standard;
	}

	&.opened {
		color: $league-two;

		&:after { content: '\f106'; }

	}

	@media only screen and (max-width: 1023px){ display: flex !important; }

}

#topNav .site-tagline-text.opened { color: $league-two; }
