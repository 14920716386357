#network {
	height: 50px;
	background: $accent-color;
	border-bottom: solid 1px $coal;
	display: none;

	.networkTeams {
		@include displayCenter($maxWidth);
		height: 50px;

		a {
			height: 50px;
			float: left;
			text-align: center;
			margin: 0 4px;
			line-height: 67px;
			transition: $transition-standard;

			&:hover { opacity: .75; }

			img { height: 65%; }

		}

	}

}


// Network Dropdown Menu

// #home {
//
// 	#networkDropMenu {
//
// 		.bar { @include displayCenter(1340px); }
//
// 		.menuWrap { @include displayCenter(1370px); }
//
// 	}
//
// }

.edit_mode {
	.networkDropTeams {
		.show-mobile {
			display: none;
			@media only screen and (max-width: 1024px){ display: block; }
		}
	}
}

.menuWrap {
	@include displayCenter($maxWidth);
	top: 0;
  z-index: 9999;

	@media only screen and (max-width: 1024px){ margin-top: 44px; }

	.networkDropTeams {
		position: absolute;
		padding: 30px 30px 10px 30px;
		background: white;
		top: 0;
		left: 0;
		border: solid 1px $gray;
		border-top: none;
		width: 100%;
		display: none;
    background: white;

		@media only screen and (max-width: 1024px){ padding: 0 30px 30px; }

		&:before {
			content: '';
			display: none;
			position: absolute;
			height: 55%;
    	width: 1px;
    	background: $light;
    	left: 48%;
    	top: 15%;
			@media only screen and (max-width: 1024px){ display: none; }

			#ngin6736 & { display: none; }
		}

		h1 {
			font-family: $fontTwo;
			font-size: 1.5rem;
			font-weight: 600;
			color: $link-color;
			width: 45%;
			float: left;
			margin-bottom: 25px;

			&.westTitle {
				margin-right: 10%;
			}

		}

		.pageElement.heroPhotoElement {
			margin: 0 0 20px 0;
			float: left;
			clear: none;
			width: 33.333333333%;

			.cutline {
				font-family: $fontTwo;
				color: $coal;
				font-style: normal;
				line-height: 40px;
				font-size: 12px;
        background-color: transparent;
        border: none;
				white-space: nowrap;
				overflow: hidden;
		    position: absolute;
		    margin-left: 60px;
				padding-left: 0;
		    text-overflow: ellipsis;
			}

		}

		a {
			height: 45px;
			width: 60px;
			float: left;
			@media only screen and (max-width: 1024px){ height: 75px; }

			&.wordLink {
				transition: $transition-standard;
				word-wrap: normal;
				color: #181824;
				width: auto;
			}

			&:hover {
				opacity: .75;
				text-decoration: none;
			}

			img {
				height: 100%;
				width: auto;

				&[src*='Harrisburg'] { width: 100%; }

			}

		}

		.west,
		.east {
			width: 45%;
			float: left;
		}

		.west { margin-right: 10%; }

		// Expansion
		.expansionTitle {
			clear: both;
			border-top: solid 1px $light;
			padding-top: 15px;
			width: 100%;
		}

		.expansion {
			clear: both;
			width: 100%;

			.pageElement.heroPhotoElement { width: 15%; }

		}

		@media only screen and (max-width: 1024px){

			.cutline { display: none; }

			h1 {
		    font-family: $fontTwo;
		    font-size: 1.5rem;
		    font-weight: 600;
		    color: #18171c;
		    margin: 15px 0 10px;
		  }

			.westTitle,
			.eastTitle { width: 100%; }

			.expansionTitle { border-top: none !important; }

		  .teamLogos {

				&.expansion .pageElement.heroPhotoElement { width: 50%; }

				.pageElement.heroPhotoElement {
					margin-bottom: 0;
					height: auto;
				}

				&.west,
				&.east {
					margin-right: 0;
					width: 100%;
				}

		    .pageElement {
		      width: 50%;
		      display: inline-block;
		      height: 100px;
		      text-align: center;
		      padding: 5%;
		      border-bottom: solid 1px $light;
		      border-right: solid 1px $light;

					a { width: 100%; }

		      &:first-of-type,
		      &:nth-of-type(2) { border-top: solid 1px $light; }

		      &:nth-of-type(odd) { border-left: solid 1px $light; }

		      img { height: 100%; }

		    }

		  }
		}

	}

}

// network menu restructure
.teamLogos {
	display: flex;
	flex-wrap: wrap;
}

.menuWrap .networkDropTeams .pageElement.heroPhotoElement {
	width: auto;
	min-width: 16.6666667%;
}

&.has-mobile-nav .menuWrap .networkDropTeams {
	padding-top: 30px;

	.teamLogos .pageElement { width: 50%; }

}

// Mobile network
.mobileNetworkBtn {
	position: absolute;
  height: 44px;
  float: right;
	display: none;
	line-height: 44px;
	font-size: 12px;
	top: 0;
  right: 15px;
	color: #f1f1f1;
	transition: color $transition-standard;
	font-family: $fontTwo;

	// .slide-nav-open & { display: none !important; }
	.native-mobile-open & { display: none !important; }


	&:before {
		content: '\f107';
		font-family: FontAwesome;
		margin-right: 5px;
		transition: content $transition-standard;
	}

	&.opened {
		color: $pop-color;

		&:before { content: '\f106'; }

	}

	@media only screen and (max-width: 1024px){ display: block !important; }

}

#topNav .site-tagline-text.opened { color: $pop-color; }
