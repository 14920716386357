.social-sharing {
    margin-left: 15px;
    display: none;

	    li {
		    width: 40px;
		    height: 39px;
		    background-color: $gray;
		    display: inline-block;
		    margin: 0 5px 5px 0;
	    	float: left;

		     &:hover { opacity: .75; }

		    a {
			    width: 40px;
			    height: 39px;
			    display: block;
			    transition: $transition-standard;

				}

			}

			.social-title { display: none; }

			.social-comments {
		    @include bgImg('share/social-comments.svg');
		    background-position: center;
		    background-color: $dark;
		    display: none;
		    background-repeat: no-repeat;
			}

			.social-facebook {
		    @include bgImg('share/social-facebook.svg');
		    background-position: center;
		    background-size: auto 50%;
		    background-color: #3b5998;
		    background-repeat: no-repeat;
			}

			.social-twitter {
		    @include bgImg('share/social-twitter.svg');
		    background-position: center;
		    background-color: #019FE9;
		    background-size: 50% auto;
		    background-repeat: no-repeat;
			}

			.social-email {
		    @include bgImg('share/social-email.svg');
		    background-position: center;
		    background-color: #76868D;
		    background-size: 50% auto;
		    background-repeat: no-repeat;
			}

	}
