#sn-site-logo {
  position: absolute;
  top: 0;
  // left: 50%;
  // left: 15px;
  left: 0;
  // margin-left: 0 - $maxWidth/2;
  // width: $site-logo-size;
  width: 145px;
  height: 100%; //60px
  // border-right: solid 1px $coal;
  padding: 5px 0;
  background-color: transparent;
  z-index: 1000;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all $transition-linear;
  // margin-top: 40px;
  padding-left: 0;

  .slide-nav-open & { display: none; }

  .edit_mode & { height: 60px;}

  .has-mobile-nav &,
  .nav-fixed.has-mobile-nav & {
    // margin-top: 0;
    height: 44px;
    border-right: none;
    width: 100px;
    left: 44px !important;
    margin-top: 0;
    padding: 5px;
  }

    // margin-left: 0 - 1400px/2;
    @media only screen and (max-width: 1450px){
      // left: 10px;
      // margin-left: 0;
    }

  .nav-fixed & {
    // width: $site-logo-size-fixed;
    // height: 100%; //45px
    // margin-top: 0;
    // left: 0;
    // margin-left: 0;
    // width: 105px;
    .sn-site-logo-background { background-position: center; }

  }

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-background-size: contain;
    // background-position: -6px center;
  }
}
