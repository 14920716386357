// Team page specif styles for USL
.siteContainer .team-nav .pageElement {
  padding: 0;
  border: none;
  margin-right: 2px;
  background: transparent;

  @media only screen and (max-width: 768px){
    margin: 0;
  }

}

.team-nav {

  .pageEl {
    float: left;

    @media only screen and (max-width: 768px){
      width: 50%;
      border-bottom: solid 2px white;

      &:nth-of-type(odd){ border-right: 2px solid white; }

    }

    @media only screen and (max-width: 450px){
      width: 100%;

      &:nth-of-type(odd){ border-right: none; }

    }

  }

  .linkElement h4 a {
    display: inline-block;
    padding: 7px 5px 3px;
    font-family: $fontTwo;
    text-transform: none;
    font-weight: 500;
    box-shadow: none;
    background: transparent;
    border-bottom: solid 3px transparent;
    transition: all $transition-standard;
    color: $coal;
    letter-spacing: .5px;
    margin-right: 15px;
    border: none;

    &:after { display: none; }

    &:hover { border-bottom: solid 3px $pop-color; }

    @media only screen and (max-width: 768px){
      display: block;
      margin: 0;
      padding: 10px 15px;
      background: #eeeeee;
    }

  }

  @media only screen and (max-width: 768px){ margin-bottom: 15px; }

}

.team-nav .selected .linkElement h4 a {
  border-bottom: solid 3px $link-color;

  @media only screen and (max-width: 768px){ border-bottom: 0; }

}
