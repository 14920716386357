// Styles for Player Search pulled from Google Sheet

// Responsive table wrapper
.tableWrapper { overflow-x: auto; }

// Structural styles for pages that include a Player Search
.player-search-page {
  #panelTwo {
    background: transparent;
    border: none;
    padding-top: 0;
  }
}

// Multi-Page results pagination
.custom-pagination {
  position: relative;;
  bottom: 0;
  width: 100%;
  text-align: center;

  @media only screen and (max-width: 768px){ text-align: center; }

  .pagination-container {
    display: inline-block;
    position: relative;

    @media only screen and (max-width: 768px){ width: 100%; }

  }

  .slick-next,
  .slick-prev {
    position: absolute;
    float: left;
    -webkit-transform: translate(0, -45%);
    -ms-transform: translate(0, -45%);
    transform: translate(0, -45%);
    border: solid 1px $gray;
    border-radius: 3px;
    transition: background $transition-standard;

    &:before {
      font-family: FontAwesome;
      color: $dark;
      font-size: 14px;
      transition: color $transition-standard;
    }

    &:hover{
      background: $pop-color;
      border: solid 1px $pop-color;
      &:before {
        color: white;
        opacity: 1;
      }
    }

    @media only screen and (max-width: 768px){
      padding: 20px;

      &:before {
        position: absolute;
        line-height: 0;
      }

    }

  }

  .slick-next {
    right: 0;

    &:before {
      content: '\f105';
      @media only screen and (max-width: 768px){ left: calc(50% - 2px); }
    }

  }

  .slick-prev {
    left: 0;

    &:before {
      content: '\f104';
      @media only screen and (max-width: 768px){ left: calc(50% - 3px); }
    }

  }

}

.player-slide-numbers {
  position: relative;
  display: inline-block !important;
  right: 0;
  float: left;
  margin: 0 40px;

  @media only screen and (max-width: 768px){
    width: calc(100% - 80px);
  }

  li {
    list-style: none;
    display: inline-block;

    &.slick-active {
      button {
        color: $link-color;
      }
    }

    button {
      border-radius: 100px;
      background: transparent;
      border: none;
      color: $dark;
      font-size: 13px;
      margin: 0 5px;
      transition: color $transition-standard;

      &:focus { outline: 0 !important; }

      &:hover {
        cursor: pointer;
        color: $pop-color;
      }

    }

  }
}

.player-results-slider.slick-dotted.slick-slider {
  margin-bottom: 15px;
}

// Player Search Form
#player-search-form {
  margin-bottom: 15px;

  #player-search-keyword {
    width: calc(100% - 135px);
    margin-right: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 10px;
    border: solid 1px $gray;
    border-radius: 0;
    -webkit-appearance:none;
    -moz-appearance: none;
    appearance: none;

    @media only screen and (max-width: 768px){ margin-bottom: 5px; }

    @media only screen and (max-width: 350px){ width: 100%; }

  }

  .player-search-submit {
    width: 130px;
    border-radius: 0;
    box-shadow: none;
    background: $link-color;
    border: none;
    padding: 8px 10px 9px;
    color: white;
    text-transform: uppercase;
    font-size: 17px;
    font-family: $fontOne;
    letter-spacing: .5px;
    margin-bottom: 10px;
    transition: background $transition-standard;
    -webkit-appearance:none;
    -moz-appearance: none;
    appearance: none;

    &:hover {
      background: $pop-color;
      cursor: pointer;
    }

    &.show-desktop { display: inline-block !important; }
    &.show-mobile { display: none !important; }

    @media only screen and (max-width: 768px){ margin-bottom: 5px; }

    @media only screen and (max-width: 350px){
      width: 100%;

      &.show-desktop { display: none !important; }
      &.show-mobile { display: inline-block !important; }

    }

  }

  .player-search-filter {
    position: relative;
    margin-right: 1%;
    border-radius: 0;
    font-size: 14px;
    border: solid 1px #c2c6ca;
    -webkit-border-radius: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 10px;
    font-weight: 500;
    background: url('https://assets.ngin.com/site_files/usl/_site/images/icons/select_arrow.svg') no-repeat;
    background-position: right 10px center;
    background-size: 15px;
    transition: border $transition-standard;
    height: 40px;

    &:focus { outline: 0 !important; }

    &:hover {
      cursor: pointer;
      border: solid 1px $pop-color;
    }

    // Currently not using the season filter.
    // Turn on if USL decieds to use and adjust filter width percentages.
    // Alos, uncomment the media query for under 640px width
    // &#season-filter {}

    &#position-filter,
    &#club-filter,
    &#status-filter { width: 32.6666666%; }
    &#status-filter { margin-right: 0; }

    // @media only screen and (max-width: 640px){
    //   width: calc(49% - 3px) !important;
    //   margin-bottom: 6px;
    //
    //   &:nth-of-type(odd){
    //     margin-right: 3px;
    //     margin-left: 0;
    //   }
    //   &:nth-of-type(even){
    //     margin-left: 3px;
    //     margin-right: 0;
    //   }
    //
    // }

    @media only screen and (max-width: 600px){
      width: 100% !important;
      margin-bottom: 5px;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

  }

}

// Results Styles
#player-search-results {
  color: $link-color;
  position: relative;
  padding-bottom: 1px;

  .no-matches { font-size: 16px; }

  .player-image-wrap {
    height: 50px;
    width: 40px;
    overflow: hidden;
    background: white;
  }

  .player-image {
    width: 60px;

    img { max-height: 60px; }

  }

  .player-name {
    font-weight: bold;

    a {
      transition: color $transition-standard;

      &:hover {
        color: $pop-color;
        text-decoration: none;
      }
    }

  }

  .country-flag { margin-right: 7px; }

}

// Results Table Styles
.player-results-table {
  width: 100%;
  text-align: left;
  position: relative;
  overflow-x:auto;

  thead {
    th {
      padding: 15px 10px;
      color: $link-color;
      font-size: 13px;

      &.player { padding-left: 0; }

    }

  }

  tbody {
    tr {
      &:nth-child(odd){ background: #dddddd; }
    }
  }

  td {
    font-size: 14px;
    white-space: nowrap;
    padding: 0 10px;

    &.player-image { padding: 0; }

  }

}

.this-page {
  color: $dark;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 17px;

  @media only screen and (max-width: 768px){
    top: -10px;
    width: 100%;
  }

}

.brody { display: none; }
