// Match Page Styls
.user_mode {

  .team-vs {
    background-color: $hover-color;
    position: relative;
    @media only screen and (max-width: 640px){ display: none; }

    &:before {
      content: 'VS';
      color: white;
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      text-align: center;
      top: calc(50% - 15px);
    }

    .column {
      @media only screen and (max-width: 768px){
        width: 50%;
        float: left;
      }
    }

    .textBlockElement h3 {
      color: white;
      font-weight: 100;
      width: 100%;
      padding: 20px 0;
      letter-spacing: .5px;
    }

    .pageEl {

      .pageElement { margin-bottom: 0; }

      .textBlockElement {

        img,
        .rightTextImage,
        .leftTextImage {
          width: auto !important;
          min-height: 0 !important;
          max-height: 50px;
        }

        .rightTextImage { margin: 10px 10px 10px 20px; }

        .leftTextImage { margin: 10px 20px 10px 10px; }

      }

    }

  }

  .game-feed {

    .column {
      padding: 0;
      @media only screen and (max-width: 1024px){ width: 100% !important; }
    }

    .pageEl .pageElement { margin-bottom: 0; }

    .videoElement,
    .video_aspect_ratio {
      height: 600px;
      max-height: 600px;
      @media only screen and (max-width: 768px){
        height: 400px;
        max-height: 400px;
      }
      @media only screen and (max-width: 640px){
        height: 300px;
        max-height: 300px;
      }
      @media only screen and (max-width: 400px){
        height: 200px;
        max-height: 200px
      }

      .video { max-height: 600px; }

    }

    .codeElement {

      @media only screen and (max-width: 1024px){
        padding: 15px 30px 0;
      }

    }

    .video_aspect_ratio {
      padding-top: 0 !important;

      &.external_controls { padding-bottom: 0; }

    }

  }

}
