.newsSlideShow-headline {
    h1 {
        a {
        font-family: $fontNewBold;
        font-size: 22px;
        text-transform: uppercase;
        }
    }
    p {
        span {
            font-family: $fontNewReg;
            font-size: 14px;
        }
    }
}

.sponsorElement {
    h3 {
        font-family: $fontNewBold;
        font-size: 24px;
        text-transform: uppercase;
    }
}
.mediaSlider {
    h2 {
        font-family: $fontNewBold;
        font-size: 32px;
    }
}
.pollElement {
    font-family: $fontNewBold;
    font-size: 28px;
}
.aggHeader {
    font-family: $fontNewBold;
    font-size: 32px;
    text-transform: uppercase;
}
#displayBodyHeader {
    span {
        font-family: $fontNewBold;
        font-size: 32px;
        text-transform: uppercase;
    }
}

//news
.newsItemElement {
    .newsItemHeader {
        a {
            h2 {
                font-family: $fontNewSemi;
                font-size: 14px;
            }
        }
    }
}
.newsAggregatorElement {
    .details {
        h4 {
            a {
                font-family: $fontNewSemi;
                font-size: 14px;
            }
        }
    }
}
//event 
.eventAggregatorElement {
    .vevent {
        h5 {
            font-family: $fontNewSemi;
            font-size: 16px;
        }
    }
}

//table 
.tableElement {
    h3 {
        span {
            font-family: $fontNewBold;
            font-size: 24px;
            text-transform: uppercase;
        }
    }
}
//comment 
.commentThreadElement {
    h3 {
        span {
            font-family: $fontNewBold;
            font-size: 32px;
            text-transform: uppercase;
        }
    }
}

//call to action
.sn-call-to-action {
    h4 {
        font-family: $fontNewBold;
        font-size: 24px;
        text-transform: uppercase;
    }
    .sn-call-to-action-subtitle {
        a {
            font-family: $fontNewBold;
            font-size: 16px;
        }
        span {
            font-family: $fontNewReg;
            font-size: 16px;
        }
    }
}

#megaFooter {
    .textBlockElement {
        h3 {
            font-family: $fontNewSemi;
            font-size: 14px;
        }
    }
}
.contactElement {
    h3 {
        .contact-element-first-name {
            font-family: $fontNewBold;
            font-size: 14px;
        }
        .contact-element-last-name {
            font-family: $fontNewBold;
            font-size: 24px;
        }
    }
}
.global-network-container .global-teams-menu .network-teams-button {
    font-family: $fontNewSemi;
    text-transform: uppercase;
}

.menu-cta {
    .textBlockElement {
        .text {
            p {
                font-family: $fontNewReg;
                font-size: 16px;
                a {
                    font-family: $fontNewBold;
                    font-size:16px;
                }
            }
        }
    }
}

.player-highlight-container.horizontal-stats .right-column-container table tbody tr:first-of-type td {
    font-size: 42px;
}
