/////////////////////////////////////////////////
/////////////////////////////////////////////////
// GLOBAL NETWORK - LEAGUE STYLES
/////////////////////////////////////////////////

//SE BAR FIXES - when se bar is enabled 
$network-teams-menu-top:110px;
$mobile-menu-top:160px;//use important with this
$se-bar-height:60px;

html {
  scroll-behavior: smooth;
}

* {
  outline: none;
}
//global network
.theme-search-wrapper {
  display: none;
}

.global-network-container {
  li {
    list-style: none;
  }
    height: 50px;
  width: 100vw;
  background: #d0d0d0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 401;
  padding: 0px 15px;
  transition: margin-top 0.2s linear;
  margin-top: 0px;
  font-family: $fontNewReg, "Arial", sans-serif;

  .page-manager-visible & {
    width: calc(100vw - 60px);
  }

  .nav-fixed:not(.has-mobile-nav) & {
    transition: margin-top 0.2s linear;
    margin-top: -50px;
    visibility: hidden;
  }

  .network-open & {
    height: 50px !important;
    visibility: visible !important;
    margin-top: 0px !important;
  }

  .has-mobile-nav & {
    height: 50px !important;
    visibility: visible !important;
  }

  .group-logo {
    width: 109px;
    height: 40px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }


  .global-teams-menu {
    width: 100%;
    height: 50px;
    content: "Network";

    .has-mobile-nav & {
      width: initial;
      right: 0;
      position: absolute;
      margin-right: 10px;
    }
  }

  .has-mobile-nav & {
    justify-content: center;
    top: 0px;
    position: absolute;

    .se-sajari-container,
    .label-connect,
    .desktop-connect {
      display: none;
    }
  }

  .sn-social-media-list {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;

    .sn-social-media-icon {
      background: #001F5B;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: $corporate !important;
      }
    }

    .has-mobile-nav & {
      display: none;
    }
  }

  .textBlockElement {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 100px;

    .has-main-nav & {
      min-width: 125px;
      margin: 0px 10px 0px 20px;
    }

    h3 {
      text-align: center;
      font-family: $fontNewReg;
      font-weight: 300;
      font-size: 12px;
      text-transform: capitalize;
      margin: 0;
      color: #001F5B !important;
    }

    .has-mobile-nav & {
      display: none;
    }
  }

  .global-teams-menu {
    .network-teams-button {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      color: #001F5B;
      font-family: $fontNewSemi;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: $corporate;
      }

      &:after {
        font-family: "FontAwesome";
        content: "\f107";
        margin: 0px 5px;
      }
    }

    &.open {
      .network-teams-button {
        &:after {
          font-family: "FontAwesome";
          content: "\f106";
          margin: 0px 5px;
        }
      }
    }
  }
}
.has-mobile-nav.has-se-bar {
  .global-network-container {
    top: $se-bar-height;
  }
}
.has-mobile-nav {
  body {
    padding-top: 100px;
  }
}
.has-mobile-nav.has-se-bar {
	body {
		padding-top: 0px;
  }
}

.networkTeamsMenu {
  li {
    list-style: none;
  }
  overflow: hidden;
  display: none;
  background: #fff;
  position: absolute;
  z-index: 401;
  top: 50px;
  width: 100%;
  border: 1px solid rgba(#999, 0.2);
  border-left: none;
  border-right: none;

  .edit_mode & {
    .elementBar {
      display: none;
    }
  }

  .has-mobile-nav & {
    height: 100vh;
  }

  .page-manager-visible & {
    width: calc(100vw - 60px);
  }

  .network-slider-container {
    .network-slider-nav-container {
      margin: 30px 0px 0px 0px;

      .column {
        display: flex;
        justify-content: center;
        align-items: center;

        .pageEl {
          margin: 0px 5px;

          .pageElement {
            margin: 0;
          }

          &:last-of-type {
            .user_mode & {
              .linkElement {
                h4 {
                  a {
                    &:after {
                      content: "\f08e";
                      font-family: "FontAwesome";
                      display: inline-block !important;
                      font-size: 12px;
                      color: $gray;
                    }

                    &:hover {
                      color: $corporate;
                      box-shadow: none;
                      transition: all 0.2s linear;

                      &:after {
                        color: $corporate;
                        transition: all 0.2s linear;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .linkElement {
          h4 {
            a {
              font-weight: 600;
              border: none;
              padding: 10px;
              font-size: 14px;
              font-family: $fontNewSemi;
              color: $gray;
              background: transparent;

              &:hover {
                background: transparent;
                box-shadow: inset 0px -2px 0px $corporate;
                color: $corporate-blue;
                transition: box-shadow 0.2s linear;
              }

              &:after {
                display: none;
              }
            }
          }
        }
      }

      .columnBounds {
        display: flex;
        border: none;
      }

      .edit_mode & {
        .columnBounds {
          .drag {
            &:last-of-type {
              .pageElementAdmin {
                .pageEl {
                  .linkElement {
                    h4 {
                      a {
                        &:after {
                          content: "\f08e";
                          font-family: "FontAwesome";
                          display: inline-block !important;
                          font-size: 12px;
                          color: $coal;
                        }

                        &:hover {
                          color: $corporate;
                          box-shadow: none;
                          transition: all 0.2s linear;

                          &:after {
                            color: $corporate;
                            transition: all 0.2s linear;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .linkElement {
              h4 {
                a {
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .network-slider-content-container {
      .pageElement {
        padding: 0;
      }
      .heroPhotoElement {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row;
        margin-bottom: 0;

        a {
          width: 100%;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;

          img {
            height: 100%;
            width: auto;
            left: 0;
            position: absolute;
            margin: 0 auto;
          }
        }

        .cutline {
          border: none;
          background: transparent;
          font-size: 16px;
          color: $coal;
          font-style: normal;
          padding: 10px 15px;
          padding-left: 30px;
          font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
        }

        &:hover {
          .cutline {
            text-decoration: underline;
          }
        }
      }

      .textBlockElement {

        .text,
        h3,
        span {
          font-family: $fontNewSemi;
          font-size: 12px;
          font-weight: 700;
          line-height: 1;
          color: $dark!important;
        }
      }

      .edit_mode & {
        .column {
          .columnBounds {
            .drag {
              .pageElement {
                margin: 0 0 15px;
              }
            }
          }
        }
      }
    }
  }

  ////MOBILE
  .network-nav-item {
    list-style: none;
    height: 45px;
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px 12px 20px;
    line-height: 1.25;

    .network-nav-forward {
      width: 44px;
      height: 44px;
      right: 0;
      border-left: 1px solid rgba(153, 153, 153, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;

      &:after {
        content: "\f105";
        font-family: "FontAwesome";
        font-size: 12px;
        color: $dark;
      }
    }
  }
}
.has-se-bar {
  .networkTeamsMenu {
    top: $network-teams-menu-top;
  }
}
#siteContainer {
  .network {
    .heroPhotoElement {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: row;

      a {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
          width: auto;
        }
      }

      .cutline {
        border: none;
        background: transparent;
        font-size: 16px;
        color: $coal;
        font-style: normal;
        padding: 10px 15px;
        font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
      }
    }
  }
}

//SLICK NETWORK BAR
.networkTeamsMenu {
  .tab-slicks-container {
    min-height: 200px;
    background: #fff;

    .layoutContainer {
      padding-left: 0;

      .column {
        padding: 0px;
      }
    }
  }

  button:before {
    color: black !important;
    transform: scale(2);
  }

  .network {
    background: #fff;
    margin: 30px 120px;
  }

  .network-nav .column {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .pageEl {
      padding: 10px;
    }
  }

  .columnBounds[data-slick-index="0"] {
    display: flex !important;
    flex-direction: column;
    flex-flow: column wrap;
    max-height: 500px;
  }

  .champGroups[data-slick-index="0"] {
    display: flex !important;
    flex-direction: column;
    flex-flow: column wrap;
    max-height: 500px;
  }

  .L1Groups {
    max-height: 500px;
    display: flex !important;
    flex-direction: column;
    flex-flow: column wrap;
  }

  .L2Groups {
    max-height: 500px;
    display: flex !important;
    flex-direction: column;
    flex-flow: column wrap;
  }
  .ACGroups {
    max-height: 500px;
    display: flex !important;
    flex-direction: column;
    flex-flow: column wrap;
  }

  .slick-slide .textBlockElement {
    //center text vert with logo
    display: flex;
    align-items: center;
    min-height: 38px;

    .leftTextImage,
    .originalTextImage,
    .rightTextImage {
      width: auto;
    }
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }

  .network-link {
    width: 100%;

    .linkElement {
      margin: 15px;

      h4 {
        display: flex;
        justify-content: center;

        a {
          border: none;
          display: inline;
          color: $coal;
          font-weight: 600;
          text-transform: initial;
          font-size: 16px;
          font-family: $fontNewSemi;
          background: transparent;

          &:after {
            content: "\f08e";
            font-size: 12px;
          }

          &:hover {
            background: transparent;
            color: $corporate;

            &:after {
              color: $corporate;
            }
          }
        }
      }
    }
  }
}

.active-slick-button {
  a {
    color: $corporate !important;
    box-shadow: inset 0px -2px 0px $corporate;
    transition: all 0.2s linear;
  }
}

//mobile
.networkTeamsMenu {
  .has-mobile-nav & {

    &-championship,
    &-leagueOne,
    &-leagueTwo,
    &-academy,
    &-w-league {
      height: 100%;
      background: #fff;
      display: none;
      z-index: 402;
      top: 50px;
      position: absolute;
      width: 100%;
      right: -100%;
      transition: right 0.2s linear;
      overflow-y: scroll;

      &.open-championship,
      &.open-leagueOne,
      &.open-leagueTwo,
      &.open-academy 
      &.open-womens {
        right: 0;
        transition: right 0.2s linear;
        display: block;
      }

      .mobile-league-header {
        display: flex;
        justify-content: center;
        height: 44px;
        position: sticky;
        width: 100%;
        z-index: 1;
        background: #fff;
        top: 0;
        color: $dark;
        border-top: 1px solid rgba(153, 153, 153, 0.2);
        border-bottom: 1px solid rgba(153, 153, 153, 0.2);

        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .close-menu {
          width: 44px;
          height: 100%;
          display: block;
          background: white;
          left: 0;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;

          &:after {
            content: "\f104";
            font-family: "FontAwesome";
            font-size: 12px;
            color: $dark;
          }
        }
      }

      .mobile-teams-list {
        .league-title {
          display: none !important;
        }
        
        .title-expansion {
          padding-left: 0;

          .textBlockElement > h3 {
            font-family: "hurme_geometric_sans_1SBd", sans-serif;
            font-size: 12px;
            font-weight: 700;
            line-height: 1;
            color: #999 !important;
          }
        }
      
      }

      .heroPhotoElement {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row;
        margin: 0 !important;
        padding: 0 !important;

        a {
          width: 100%;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;

          img {
            height: 100%;
            width: auto;
            left: 0px;
            margin: 0;
            position: absolute;
          }
        }

        .cutline {
          border: none;
          background: transparent;
          font-size: 16px;
          color: $coal;
          font-style: normal;
          padding: 10px 15px;
          padding-left: 30px;
        }

        &:hover {
          .cutline {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.slider {
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: block;
}

.slider-content {
  float: left;
  width: 100%;
  display: block;
  overflow: auto;
  min-height: calc(100vh - 94px);

  li {
    float: left;
    width: 100%;
    list-style: none;

    .alpha-nav-title {
      width: 100%;
      float: left;
      font-size: 0px;
    }
  }
}

.slider {
  .slider-nav {
    position: absolute;
    right: 0;
    top: 0px !important;
    // background: rgba(153, 153, 153, 0.2);
    min-height: 100%;
    border-left: 1px solid rgba(153, 153, 153, 0.2);

    ul {
      padding: 5px 0;
    }

    li a {
      padding: 3px 5px;
      line-height: 13px;
      text-align: center;
      color: rgba(153, 153, 153, 0.5);
      font-weight: bold;
      display: block;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.has-mobile-nav {
  body {
    &.network-open {
      overflow: hidden;
    }
  }
}

.edit_mode {
  .networkTeamsMenu {
    .network {
      .columnBounds {
        border: none;
      }
    }
  }

  .networkTeamsMenu {
    &:hover {
      transition: all 0.2s ease-in-out;

      &:before {
        content: "Team network menu is only editable by USL Corporate admin users.";
        color: #fff;
        background: $gray;
        width: 100%;
        height: 30px;
        z-index: 1;
        position: absolute;
        top: 0px;
        text-align: center;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
// html.collapsed-mobile-nav.slide-nav-open .theme-nav-style-slide {
//   top: 100px;
// }
// html.collapsed-mobile-nav .theme-nav-style-slide {
//   top: 100px;
// }
html.has-se-bar.collapsed-mobile-nav.slide-nav-open .theme-nav-style-slide {
  top: $se-bar-height!important;
}
html.has-se-bar.collapsed-mobile-nav .theme-nav-style-slide {
  top: 0px!important;
}
// League Social
.networkTeamsMenu {
  .league-social {
    text-align: center;

    .sn-social-media-list {
      align-items: center;
      display: flex;
      justify-content: center;

      .sn-social-media-icon {
        background-color: $gray;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;

        &:hover {
          background-color: $corporate;
        }
      }
    }
  }
}
.has-se-bar.has-mobile-nav {
  #siteContainer {
    padding-top: 94px!important;
  }
}

.networkTeamsMenu {
  .slick-slide {
    .heroPhotoElement {
      img {
        width: auto;
      }
    }
  }
}

.slick-slide {
  .league-title {
    display: none;
  }
}