/* News Slideshow Styles */
// 1. Global Styling
// 2. Thumbnails
// 3. Controls
// 4. Buttons
// 5. Aspect Ratio Adjustments
// 6. Accomidations for Small Columns & Mobile Views

// Variables
$control-height: 2.5rem;

// 1. Global Styling
.drag .newsSlideShow { cursor: pointer; }

.newsSlideShow-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: $control-height;
}

.newsSlideShow-article {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - #{$control-height});
  border: 1px solid $gray;
  background-color: #fff;

  &.current {
    z-index: 1;
  }

  img { position: absolute; }
}

.newsSlideShow-headline {
  position: absolute;
  bottom: .25rem;
  z-index: 1;
  width: 100%;
  margin: 0;

  h1 {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 0 2px 0px;
    padding: 20px;
    font-size: rem(18);
    background-color: rgba(0,0,0,0.5);
    text-transform: none;
    font-family: "europa" !important;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;

    &:before {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      left: -7px;
      height: 100%;
      width: 5px;
      background-color: $accent_color;
    }

    &:after {
      content: "";
      clear: both;
    }

    a {
      display: block !important;
      width: 100%;
      color: #fff;
      font-size: rem(22);
      line-height: 1.2;
      text-decoration: none;
      overflow: hidden;

      @media only screen and (min-width: 768px){
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      // Sadly, `line-clamp` doesn't work in non-webkit browsers, so we need
      // to restrict it with this hack. `white-space: nowrap` serves as a
      // fallback for other browsers with one line of text instead of two.
      // @media screen and (-webkit-min-device-pixel-ratio:0) {
      //   display: -webkit-box;
      //   -webkit-line-clamp: 2;
      //   -webkit-box-orient: vertical;
      //   white-space: nowrap;
      //
      //   .col-md-3 & {
      //     -webkit-line-clamp: 1;
      //   }
      // }
    }
  }

  p {
    @extend %clearfix;
    display: block;
    width: 96%;
    padding-left: 0px;
    margin: 0 0 2px 0;
    line-height: 1.5em;
    font-size: rem(12);
    font-style: italic;

    span {
      display: block;
      float: left;
      width: auto;
      max-width: calc(100% - 142px);
      height: 2.25rem;
      padding-right: 10px;
      padding-left: 12px;
      line-height: 2.25rem;
      color: #fff;
      background-color: rgba(0,0,0,0.5);
      text-indent: 10px;
      overflow: hidden;
      @include truncate-text;

      &:empty { display:none; }
    }

    a {
      float: left;
      width: 140px;
      margin: 0;
      height: 2.25rem;
      line-height: 2.25rem;
    }
  }
}

// 2. Thumbnails
.newsSlideShow-thumbnails {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 2px;
  width: calc(100% - 5rem); // Subtract width of left/right arrows
  height: $control-height;
  // box-shadow: 0 -2px $borderColor;
  list-style-type: none;
  line-height: 1;
  text-align: center;
  // background-color: #fff;
  cursor: default;
  transform: translateX(-50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .col-md-3 & {
    font-size: .5em;
  }

  @media only screen and (max-width: 450px) {
    font-size: .5em;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    padding: 4px;

    .col-md-3 & {
      padding: 6px;
    }

    @media only screen and (max-width: 450px) {
      padding: 6px;
    }

    &:before {
      content: '';
      display: block;
      width: .5em;
      height: .5em;
      border-radius: 100%;
      background-color: $link_color;
      transition:
        transform .1s ease-in-out,
        background-color .1s ease-in-out;
    }

    &:hover,
    &.current {
      &:before {
        background-color: $accent_color;
        transform: scale(1.375,1.375);
      }
    }
  }

  img {
    display: none;
    width: 100%;
  }
}

// 3. Controls
.newsSlideShow-controls {}

.newsSlideShow-prev.pill-small-left,
.newsSlideShow-next.pill-small-right {
  position: absolute;
  z-index: 10;
  bottom: 1px;
  // overflow: hidden;
  width: $control-height;
  height: $control-height;
  padding: 0;
  background-color: transparent;
  // box-shadow: 0 -2px $borderColor;
  font-size: 1em;
  text-align: center;
  color: $link-color;
  font-size: 0px; //
  overflow: visible; //
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $pop-color;
    // background-color: #fff;

    &:before {
      transform: scale(1.375);
    }
  }

  &:before {
    line-height: 1; //2.5
    content: '\f104'; // fa-arrow-right
    display: block;
    color: inherit;
    font-family: 'fontawesome';
    font-size: 28px; //
    transition:
      transform .1s ease-in-out,
      color .1s ease-in-out;
  }
}

.newsSlideShow-prev.pill-small-left {
  left: 0;
}

.newsSlideShow-next.pill-small-right {
  right: 0;
}

.newsSlideShow-next.pill-small-right:before {
  content: '\f105'; // fa-arrow-left
}

.newsSlideShow-empty {
  text-align: center;

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}

// 4. Buttons
.newsSlideShow-more.button-small {
  display: block;
  position: relative;
  background-color: $link_color;
  color: #fff;
  font-family: $fontTwo;
  font-size: rem(14);
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  text-indent: -20px;
  text-align: center;
  border: 0;
  transition: background-color $transition-standard;

  &:after{
    content: "\f105"; // fa-arrow-left
    font-family: FontAwesome;
    font-size: rem(14);
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: 56px;
    color: $accent_color;
    transition: color $transition-standard;
  }

  &:hover{
    background-color: $pop-color;
    border: 0;

    &:after{
      color: #fff;
    }
  }
}

.newsSlideShow-stop,
.newsSlideShow-play { display: none !important; }

// 5. Aspect Ratio Adjustments
.aspect_ratio_original,
.aspect_ratio_1_1,
.aspect_ratio_4_3,
.aspect_ratio_16_9,
.aspect_ratio_2_1,
.aspect_ratio_3_1,
.aspect_ratio_4_1,
.aspect_ratio_8_5 { height: initial !important; }

.aspect_ratio_original { @include aspect-ratio(16,9); }
.aspect_ratio_1_1 { @include aspect-ratio(1,1); }
.aspect_ratio_4_3 { @include aspect-ratio(4,3); }
.aspect_ratio_16_9 { @include aspect-ratio(16,9); }
.aspect_ratio_2_1 { @include aspect-ratio(2,1); }
.aspect_ratio_3_1 { @include aspect-ratio(3,1); }
.aspect_ratio_4_1 { @include aspect-ratio(4,1); }
.aspect_ratio_8_5 { @include aspect-ratio(8,5); }

// 6. Accommodations for Small Columns & Mobile Views
@media only screen and (min-width: 767px) {
  .col-md-3,
  .col-md-4 {
    .newsSlideShow-headline {
      h1 a {
        font-size: rem(18);
      }
      p {
        display: none;
      }
    }
  }
  .col-md-3,
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    .aspect_ratio_4_1,
    .aspect_ratio_3_1,
    .aspect_ratio_2_1 {
      .newsSlideShow-headline {
        padding-bottom: 2em;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .newsSlideShow-headline {
    .aspect_ratio_4_1 &,
    .aspect_ratio_3_1 &,
    .aspect_ratio_2_1 & {
        padding-bottom: 2em;
    }
    h1 a {
      font-size: rem(18);
    }
    p {
      display: none;
    }
  }
}
@media only screen and (max-width: 350px) {
  .newsSlideShow-headline {
    p {
      display: none;
    }
  }
}


// Custom Styled NS
.newsSlideShow-article {
  height: 100%;
  border: none;
}

.newsSlideShow-more.button-small { display: none; }

.newsSlideShow-headline {
  bottom: 40px;
  background-color: rgba(0,0,0,.75);
  left: 2%;
  width: auto;
  max-width: 96%;
  padding-bottom: 10px;

  @media only screen and (max-width: 450px){
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
  }

  h1 {
    text-transform: uppercase;
    margin: 0;
    padding: 10px 20px 0;
    background: transparent;

    @media only screen and (max-width: 450px){ padding: 10px 20px 0; }

  }

  p {
    font-size: 14px;
    font-style: normal;

    span {
      background: transparent;
      max-width: 100%;
      color: $gray;
      letter-spacing: .5px;
    }

  }

}

.newsSlideShow-next.pill-small-right,
.newsSlideShow-prev.pill-small-left {
  color: #fff;
  bottom: 47%; //50%
}

.newsSlideShow-thumbnails {
  @media only screen and (max-width: 450px){ display: none; }

  a {

    &:before {
      background-color: $gray;
      height: 12px;
      width: 12px;
    }

    &.current:before,
    &:hover:before {
      background-color: $pop-color;
      transform: none;
    }

  }

}
