//CUSTOM CTA WITH MENU, BUILT WITH TEXT BLOCK
.menu-cta {
  .textBlockElement {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid $link-color;
    .leftTextImage {
      margin: 0px;
      float: none;
      background-color: black;
      img {
        opacity: .8;
        width: 100%;
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
    .text {
      position: absolute;
      width: 70%;
      text-align: center;
      background-color: #fff;
      h3 {
        background-color: $link-color;
        margin: 0;
        color: #fff;
        padding: 15px 0px;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 0;
        color: #181824;
        font-size: 14px;
        padding: 5px;
        a {
          font-size: 14px;
        }
        &:last-of-type {
          margin-bottom: 10px;
        }
      }
    }
  }
}

//CUSTOM CTA WITH BUTTON
.btn-cta {
  .sn-call-to-action img {
    transform: none;
    left: 0;
    top: 0;
    position: relative;
    transition: transform .2s;
  }
  .sn-call-to-action {
    border-bottom: 5px solid $link-color;
    padding: 0;
    &:before, &:after {
      display: none;
    }
  }
  .sn-call-to-action-overlay {
    background-color: rgba(0,0,0,.2);
  }
  .sn-call-to-action-title {
    font-size: 42px;
    font-weight: 600;
  }
  .sn-call-to-action-subtitle {
    background-color: rgba($link-color, .5);
    border: solid 1px $link-color;
    text-align: center;
    color: #fff;
    padding: 15px 20px;
    margin-bottom: 0px;
    line-height: 1.3;
    text-decoration: none;
    transition: all .2s;
    font-family: $fontTwo;
    font-size: 14px;
    line-height: 1px;
    text-transform: uppercase;
    display: inline-block;
    span {
      padding-top: 0px;
    }
    &:after {
      color: #fff;
      content: "\f105";
      font-family: FontAwesome;
      display: inline-block;
      line-height: .8em;
      margin: 0 0 0 6px;
      padding: 0;
      position: relative;
      transition: color .2s;
    }
  }
  &:hover  {
    transition: all .2s;
    .sn-call-to-action-subtitle {
      background-color: $link-color;
    }
    .sn-call-to-action img { //.sn-variation-tall-text 
      transform: scale(1.03);
      transition: transform .2s;
    }

  }
}

.border-cta {
  .sn-call-to-action {
    border-bottom: 5px solid $link-color;
    padding: 0;

    &:before, &:after {
      display: none;
    }

  }
  .sn-call-to-action-overlay {
    background-color: rgba(0,0,0,.2);
    padding: 15px;
    .sn-call-to-action-overlay-inner {
      border: 1px solid $link-color;
    }
  }
  .sn-call-to-action-title {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    line-height: 1.5;
  }
  .sn-call-to-action-subtitle {
  font-size: 14px;
  line-height: 1.5;
  }
  
}
