// Mixins
@mixin playlistFullWidth(){
  .featuredVideo {
    width: 100%;
  }
  .loadedVideos {
    width: 100%;
    border-left: solid 1px $gray;
  }
  .videoPlaylist:after { width: calc(100% - 30px); }
}
@mixin hasThumb(){
  .loadedVideos {

    h3 { margin-left: 85px; }

    span { display: block; }

  }
}
@mixin hideThumb(){
  .loadedVideos {

    h3 { margin-left: 0; }

    span { display: none; }

  }
}
@mixin layoutHalf(){
  .featuredVideo { width: 65%; }

  .mainVideo { height: 166px; }

  .loadedVideos { width: 35%; }
}
@mixin playlistQuarter(){
  .featuredVideo {
    width: 100%;
  }
  .loadedVideos {
    width: 100%;
    border-left: solid 1px $gray;
  }
  .mainVideo { height: 118px; }
  .videoPlaylist:after { width: calc(100% - 30px); }
}
@mixin setHeight($theHeight){
  .layout-100,
  .layout-50-50,
  .layout-66-33,
  .layout-33-66,
  .layout-33-33-33,
  .layout-25-25-50,
  .layout-25-50-25,
  .layout-50-25-25 {

    .mainVideo,
    .loadedVideos { height: $theHeight !important; }

  }
}


// Video Palylist Styles
.loadedVideos {
  width: 25%;
  height: 100%;
  overflow: scroll;
  white-space: nowrap;
  float: left;
  border-left: none;
  overflow-y: scroll;
  position: relative;
  background: $light;
  background: -webkit-linear-gradient(white 50%, #eaeaea 200%);
  background: -o-linear-gradient(white 50%, #eaeaea 200%);
  background: -moz-linear-gradient(white 50%, #eaeaea 200%);
  background: linear-gradient(white 90%, #eaeaea 100%);
  border: solid 1px $gray;
  border-left: none;

  ul {
    padding: 0px;
    margin: 0px;
    width: 100%;
    display: inline-block;
    list-style: none;

    li {
      background: transparent;
      border-bottom: 1px solid $gray;
      padding: 17px 10px;
      width: 100%;
      float: left;
      box-sizing: border-box;
      white-space: normal;
      transition: background .2s ease-in-out;

      &:last-child{
        border-bottom: none;
      }

      &.active {
        background: $light;

        h3{
          color: #222222;

          &:after  { background: linear-gradient(to right, rgba(220, 220, 220, 0), rgba(220, 220, 220, 1) 50%); }

        }

        span {
          &:before {
            content: 'NOW';
            position: absolute;
            display: inline-block;
            height: 25px;
            width: 100%;
            left: 0;
            bottom: 0;
            background: rgba(0,0,0,.75);
            text-align: center;
            line-height: 25px;
            color: white;
            font-family: $fontTwo;
            font-size: 14px;
          }
        }

        &:hover {

          span {

            &:after { display: none; }

            &:before { background: rgba($accent-color, .75); }

          }

        }

      }

      &:hover {
        background: $light;
        cursor: pointer;

        span {
          &:after {
            opacity: 1;
          }

        }

      }

    }

  }

  img {
    width: 100%;
    margin-top: -8px;
  }

  span {
    width: 75px;
    height: 40px;
    overflow: hidden;
    position: relative;
    float: left;
    display: none;

    &:after {
      content: '\f144';
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,.75);
      top: 0;
      left: 0;
      font-family: FontAwesome;
      text-align: center;
      line-height: 40px;
      font-size: 25px;
      color: white;
      opacity: 0;
      -webkit-font-smoothing: antialiased;
      transition: opacity .2s ease-in-out;
    }

  }

  h3 {
    color: #333333;
    font-size: 11px;
    font-family: $fontTwo;
    text-transform: none;
    margin-left: 0;
    line-height: 1.4em;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    min-height: 126px;
    overflow: hidden;
    display: none;
  }

}

/*playlistResponse*/
.playlistVideos{

  li { list-style: none; }

  h3 {
    color: #000;
    margin: 20px auto;
  }


}

.featuredVideo{
  width: 75%;
  float: left;
  background: black;

  iframe { display: none; }

  iframe:first-child{ display: block; }

}

// layout 100
.layout-100 {
  @include hasThumb();
  @media only (min-width: 768px) and (max-width: 1024px) { @include hideThumb(); }
}

// layout 66-33
.layout-66-33 {

  .column-1 {
    .mainVideo {
      height: 258px;
    }
  }

  .column-2 {
    .mainVideo {
      height: 162px;
    }
    @include hasThumb();
    @include playlistFullWidth();
  }

}

// layout 50-50
.layout-50-50 { @include layoutHalf(); }

// layout 33-66
.layout-33-66 {

  .column-1 {
    .mainVideo {
      height: 162px;
    }
    @include hasThumb();
    @include playlistFullWidth();
  }

  .column-2 {
    .mainVideo {
      height: 258px;
    }
  }

}

// layout 33-66
.layout-33-33-33 {

  .mainVideo {
    height: 162px;
  }
  @include hasThumb();
  @include playlistFullWidth();

}

// layout 50-25-25
.layout-50-25-25 {

  .column-1 { @include layoutHalf(); }

  .column-2,
  .column-3 { @include playlistQuarter(); }

}

// layout 25-50-25
.layout-25-50-25 {

  .column-2 { @include layoutHalf(); }

  .column-1,
  .column-3 { @include playlistQuarter(); }

}

// layout 25-25-50
.layout-25-25-50 {

  .column-3 { @include layoutHalf(); }

  .column-1,
  .column-2 { @include playlistQuarter(); }

}

//BREAKPOINTS
// @media only screen and (max-width: 901px) {
//   .layout-100 {
//    .loadedVideos { height: 492px !important; }
//   }
// }
@media only screen and (max-width: 1024px){
  // layout 100
  .layout-100 {

    .featuredVideo { width: 65%; }
    .loadedVideos { width: 35%; }
    .mainVideo,
    .loadedVideos { height: 280px !important; }

  }

  .layout-50-50 {
    .featuredVideo { width: 100%; }

    .mainVideo,
    .loadedVideos { height: 200px !important; }

    .loadedVideos {
      width: 100%;
      border-left: solid 1px $gray;
    }
  }


}

@media only screen and (max-width: 768px){
  // layout 100
  .layout-100,
  .layout-50-50,
  .layout-66-33,
  .layout-33-66,
  .layout-33-33-33,
  .layout-25-25-50,
  .layout-25-50-25,
  .layout-50-25-25 {

    .featuredVideo { width: 100%; }

    .mainVideo,
    .loadedVideos { height: 390px !important; }

    .loadedVideos {
      width: 100%;
      border-left: solid 1px $gray;

      span:after { display: none !important; }

      span { display: inline-block; }

      h3 { margin-left: 85px; }

      ul li.active:hover span:before { background: rgba(0,0,0,.75); }

    }

  }

}

@media only screen and (max-width: 640px){
  @include setHeight(300px);
}

@media only screen and (max-width: 480px){
  @include setHeight(190px);
}
