// No padding on pageElement
.no-pad,
#home .no-pad,
#panelThree .no-pad,
#panelTwo .no-pad {
  .pageElement {
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
  }
}

// Social Connect
.connect {
  margin-left: 10px;
  .sn-social-media-list .sn-social-media-icon {
    color: $link-color;
    background: $dark !important;
    border-radius: 2px;
    margin: 0;
    transition: background $transition-standard;
    &:hover { background: $pop-color !important; }
  }
}
.desktop-connect {
  flex: 0 0 auto;
  .sn-social-media-list .sn-social-media-icon {
    // vertical-align: 2px;
    font-size: 1.5em;
    &:before {
      line-height: 1.76em;
    }
  }
}

// Custom Team Buttons
.team-button {

  .textBlockElement,
  &.textBlockElement {
    position: relative;
    border: solid 1px $gray;
    padding: 15px 15px 15px 80px;

    &:hover {
      background-color: $light;

      > h3 {
       
        &:after {
          opacity: 1;
          right: 0;
        }
      }

    }

    > h3 {
      float: left;
      padding-left: 0;
      margin-bottom: 0;
      -webkit-font-smoothing: antialiased;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 14px;
      font-family: $fontTwo;
      position: relative;
        a {
          &:hover {
            text-decoration: none !important;
          }
        }

      &:after {
        content: '\f105';
        font-family: FontAwesome;
        color: $pop-color;
        position: absolute;
        right: 15px;
        top: 0;
        opacity: 0;
        transition: all .3s ease-in-out;
      }

    }

    .leftTextImage,
    .rightTextImage {
      float: left;
      margin: 0;
      width: auto !important;
      min-width: 70px;
      position: absolute;
      min-height: 0 !important;
      border-right: solid 1px $gray;
      top: 0;
      left: 0;
      height: 100%;
      padding: 5px 10px;
      transition: background-color $transition-standard;
      background: white;

      a,
      img {
        height: 100%;
        width: auto !important;
        text-decoration: none !important;
      }
      a:hover {
        text-decoration-line: none !important;
      }

    }

  }

}

@media only screen and (max-width: 1024px) {
  .team-button .textBlockElement .leftTextImage, 
  .team-button .textBlockElement .rightTextImage, 
  .team-button.textBlockElement .leftTextImage, 
  .team-button.textBlockElement .rightTextImage {
    min-width: 70px;
    max-width: 70px;
    display: flex;
    align-items: center;
    position: initial;
    a,
    img {
      height: auto;
      width: 100%;

    }
  }
  .team-button .textBlockElement, .team-button.textBlockElement {
    padding: 0;
    position: initial;
    display: flex;
    align-items: center;
    .text {
      h3 {
        padding-left: 15px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .team-button {
    .textBlockElement {
      .text {
        h3 {
          line-height: 1;
          padding-left: 10px;
          a {
            font-size: 17px;
        
          }
        }
      }
    }
  }
}

// Mobile Connect
.mobile-connect {
	text-align: center;
	padding: 10px;
	.sn-social-media-list {

		.sn-social-media-icon {
			height: 1.5em !important;
			width: 1.5em !important;
			background: $gray;

			&:before {
				width: 100%;
				height: 100%;
    		font-size: 16px;
				color: $link-color;
				line-height: 1.85em;
			}

		}

			&.light,
			&.dark {
					.sn-social-media-icon {
						background: $gray;
					}
			}
	}
}

// Temporary team button sty
// .widget-teamButtons {

//   a {
//     background: #fff;
//     border: solid 1px $gray;
//     transition: background $transition-standard;
//     border-bottom: solid 1px $gray;
//     color: $coal;
//     padding: 10px 15px;
//     position: relative;
//     font-family: $fontTwo;
//     text-transform: uppercase;

//     &:after {
//       opacity: 1;
//       right: 0;
//     }

//     &:hover {
//       background: $light;

//       &:after {
//         content: '\f105';
//         font-family: FontAwesome;
//         color: $pop-color;
//         position: absolute;
//         right: 15px;
//         top: 0;
//         opacity: 0;
//         transition: all .3s ease-in-out;
//       }

//     }
//   }

//   .teamLogo-small { display: none; }

// }

.square-ad {
  .heroPhotoElement img { max-width: 300px; }
}

// .game-header .team-crest .team-logo { background-size: 80%; }
.game-header .team-crest .team-logo { background-size: contain; }

.sportTableLabel {
  .teamLogo-tiny  { display: none; }

  .teamName { padding-left: 0 !important; }

}

.minimal-agg {
  .thumbnail {
    display: none !important;
  }
  .details {
    padding-left: 0px !important;
  }
}

#home {
  #panelTwo {
    background: transparent;
    border: none;
  }
}

.leaderboard-img {
  max-width: 728px;
  margin: 0px auto;
}

.link-pad {
  background: none !important;
  border: none !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
}

span.theme-nav-forward.page_node_2333971 {
    display: none;
}

.zeroVisability {
  visibility: hidden !important;
}

// Match Links
.custom-match-link{
  text-decoration: none;

  &:hover {
    text-decoration: none;

    a { text-decoration: none; }

  }

}

// reaptcha

.pollElement ul li.voteButton {

  input:disabled {
    background: #E6E6E6;
    border-bottom: 3px solid #E6E6E6;
    color: #999999;

    &:hover {
      border-bottom: 3px solid #E6E6E6;
      background: #E6E6E6 !important;
      color: #999999;
      cursor: not-allowed;
    }

  }

  &:after { color: #999999; }

  &:hover::after { color: #999999; }

}

//Site Guide Accordion
.accordion-header {
  h3 {
    border-bottom: 2px solid $pop-color;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
      content: "\f107";
      color: $link-color;
      display: inline-block;
      position: relative;
      font-size: 18px;
      font-family: FontAwesome;
      float: right;
      padding-right: 15px;
      padding-left: 15px;
      .active & {
        transform:rotateX(180deg);
        color: $pop-color;
      }
    }
  }
}

img[src*="loggly.com"] {
    display: none !important;
}

// Media Slider 

.sn-media-slider .slider.bottom-center-paging .slider-pagination, .sn-media-slider .slider.bottom-center-paging .slider-pagination-numbers {
  bottom: 15px;
    text-align: center;
}

.team-button {
  .textBlockElement {
    h3 {
      a {
        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}

.theme-nav-forward.page_node_3806180 {
  display: none;
}
