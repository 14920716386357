//*** RIGHT RAIL STYLES ***//
.user_mode.right-rail-layout {

  &#home {
    #panelTwo {
      // Uncomment padding stylign below once Top Banner Ad Network is enabled
      padding-top: 0;
      padding-right: 0;
      width: calc(100% - 330px);
      border: none;
      background: transparent;
      margin-right: 0;
      margin-left: 0;
      padding-left: 15px;
      @media only screen and (min-width: 1024px){
          padding-right: 0;
          margin-right: 0;
      }
      @media only screen and (max-width: 1024px){
        width: calc(100% - 330px);
        padding: 15px 0;
        margin-left: 0;
      }
    }
    @media only screen and (min-width: 1200px){
      // Uncomment padding stylign below once Top Banner Ad Network is enabled
      // #panelThree { margin-top: -120px; }
    }
  }

  #panelTwo {
    width: calc(100% - 375px);
    max-width: 1085px;
    float: left;
    margin-left: 30px;
    @media only screen and (min-width: 1024px){
      margin-right: 15px;
    }
  }

  #panelThree {
    width: 100%;
    max-width: 330px;
    float: left;
    padding-right: 15px;
    display: none;

    .column {
      padding-left: 0;
      @media only screen and (max-width: 1024px){ padding-right: 0; }
    }

  }

  @media only screen and (min-width: 1200px){
    &.home {
      #adWrapperTopLeaderboard {
        width: calc(100% - 330px);
        max-width: 1085px;
        float: left;
        margin: 0;
      }

      // #panelThree { padding-top: 15px; }

    }
  }

  @media only screen and (max-width: 1024px){

    &#home {
      #panelTwo { width: 100% !important; }
      #panelThree { padding-top: 0; }
    }

    #panelTwo {
      width: calc(100% - 30px) !important;
      max-width: 100%;
      float: left;
      padding: 15px 0 0 0;
      margin-left: 15px;
      margin-right: 15px;
    }

    #panelThree {
      max-width: 100% !important;
      padding: 15px;
      float: left;
    }

  }

}

#panelTwo {
  background: white;
  border: solid 1px $gray;
  padding: 15px 0;
  margin: 0 15px;
  width: calc(100% - 30px);
  display: none;
  .leaguePage & {
    padding: 15px;
  }

  @media only screen and (max-width: 1300px){
    width: calc(100% - 30px);
    margin: 0 15px;
  }

  .edit_mode & { padding: 15px; }

}

//*** RESPONSIVE SPECIFIC STYLES ***//
.user_mode {

  .show-desktop {
    display: block !important;
  }

  .show-mobile {
    display: none !important;
  }

  .desktop-view {
    .pageEl, &.pageEl { display: block !important; }
  }

  .mobile-view {
    .pageEl, &.pageEl { display: none !important; }
  }

  @media only screen and (max-width: 1024px){
    .show-desktop {
      display: none !important;
    }

    .show-mobile {
      display: block !important;
    }
  }

  @media only screen and (max-width: 768px){
    .desktop-view {
      .pageEl, &.pageEl { display: none !important; }
    }
    .mobile-view {
      .pageEl, &.pageEl { display: block !important; }
    }
  }

}


.edit_mode {
  .right-rail,
  .top-rail {
    display: none;
  }
}
.user_mode {
  .top-rail {
    display: none;
  }
  .top-rail-mobile {
    display: none;
  }
}

.user_mode {
  &.top-rail-layout {
    .top-rail {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 90px;
        width: auto;
      }
    }
    .top-rail-mobile {
      display: none;
    }
  }
  &.top-rail-layout-mobile {
    .pageEl.top-rail {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    .top-rail-mobile {
      @media only screen and (max-width: 767px) {
        display: block;
        img {
          max-width: 250px;
          max-height: 100%;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  &.top-layout {
    .top-rail, .top-rail .codeElement {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 90px;
        width: auto;
      }
    }
    &.top-rail-layout-mobile {
    
      .top-rail .pageEl:not(.top-rail-mobile){
        @media only screen and (max-width: 1046px) {
          display: none;
        }
      }
      .top-rail-mobile {
        @media only screen and (max-width: 1046px) {
          display: block;
          margin-top: 15px;
          img {
            max-width: 250px;
            max-height: 100%;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  &.top-rail-layout-square {
    .top-rail {
      img {
        max-width: 250px;
        max-height: 100%;
        width: 100%;
        height: auto;
      }
    }
  }
}