// Custom Event Agg Styles //

.user_mode,
.user_mode#home {

  .custom-agg, .customAgg {
    overflow: hidden;

    h4 {
      font-family: $fontOne;
      a {
        @media only screen and (max-width: 768px){ font-size: 6vw !important; }
      }
    }

    .pageElement {
      padding: 0;
      border: none;
      position: relative;
    }

    .item.extendedOptions {
      display: none;
      border-top: none;
    }

    .aggHeader,
    .dateAuthor,
    .readMore { display: none; }

    .details {
      position: absolute;
      padding-left: 5% !important;
      bottom: 25px;
      padding: 0 5%;

      h4 {
        overflow: hidden;

        a {
          color: white;
          font-size: 45px;
          font-weight: 700;
          letter-spacing: 1.5px;
          line-height: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: antialiased;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          &:hover { text-decoration: none; }
        }

      }

      .teaser {
        color: white;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: none;
        font-family: $fontTwo;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .agg-tag {
        color: white;
        text-transform: uppercase;
        padding: 5px 10px;
        background: $hover-color;
        margin-right: 10px;
        padding-left: 5%;
        background: $hover-color;
        font-size: 14px;
        color: white;
        left: 0;
        position: absolute;
        top: -40px;
      }

    }

    .newsAggregatorElement {

      .item {
        padding: 0;
        // border-bottom: solid 4px $gray;
        // transition: border-bottom $transition-standard;
        box-shadow: 0px 5px 0px $gray;
        transition: box-shadow $transition-standard;

        > a {
          padding: 0;
          background: black;
          width: 100%;
          &:hover { text-decoration: none; }
        }

        img {
          width: 100% !important;
          opacity: .85;
          transition: opacity $transition-standard;
        }

        &:hover {
          // border-bottom: solid 5px $pop-color;
          box-shadow: 0px 5px 0px $pop-color;
          transition: box-shadow $transition-standard;
          img { opacity: 1; }
        }

      }

    }

  }

}

.user_mode .layout-100 .custom-agg .details h4 a { font-size: 4vw !important; }
.user_mode .layout-50-50 .custom-agg .details h4 a {
  font-size: 3vw !important;
  @media only screen and (max-width: 1200px){ font-size: 2vw !important; }
}
