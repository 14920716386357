.directory {
  margin: 15px 0px 45px 0px;
  .reportTableElementHeader {
    display: none;
  }
  select {
    position: relative;
    border-radius: 0px;
    font-size: 14px;
    border: solid 1px #c2c6ca;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 10px;
    font-weight: 500;
    transition: border .2s ease-in;
    height: 40px;
    width: 280px;
    background-color: #eee;
    background-image: url(https://assets.ngin.com/site_files/usl/_site/images/icons/select_arrow.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 15px;
    .has-mobile-nav & {
      width: 180px;
    }
  }
  #user_filters_form {
    display: flex;
    align-items: center;
    // #user-filters-submit {
    //   display: none;
    // }
    #user-filters-submit {
      background-color: transparent;
      border: solid 1px #181824;
      text-align: center;
      color: #fff;
      background: #181824;
      margin-bottom: 0px;
      line-height: 1.3;
      text-decoration: none;
      transition: background-color .2s;
      transition: color .2s;
      text-transform: capitalize;
      padding: 10px 15px;
      font-family: $fontTwo;
      font-size: 14px;
      height: 40px;
      &:hover {
        background: #00b663;
        transition: background-color .2s, border .2s;
        border: solid 1px #00b663;
      }
    }
  }
  .report-container {
    border: none;
    border-top: 1px solid #ddd;
    .has-mobile-nav & {
      border: none;
    }
    .dataTable {
      border: none;
      thead {
        display: none;
      }
      tbody {
        tr {
          height: 130px;
          display: flex;
          border-bottom: 1px solid #ddd;
          align-items: center;
          justify-content: flex-start;
          .has-mobile-nav & {
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-bottom: 1px solid #ddd;
            padding-bottom: 15px;
          }
          td {
            .has-mobile-nav & {
              min-height: 50px;
              text-align: center;
            }
            // border-bottom: 1px solid #DDDDDD;
            padding: 0px 5px;
            height: 100%;
            display: flex;
            align-items: center;
            .has-mobile-nav & {
              border: none;
            }
            .directory-icon-container-link {
              text-decoration: none;
              font-weight: 500 !important;
              .directory-icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
                .directory-icon {
                  width: 15px;
                  height: 15px;
                  margin: 5px;
                  justify-content: center;
                  align-items: center;
                  font-size: 15px;
                  .directory-icon-text {
                    font-size: 12px !important;
                  }
                }
              }
              &:hover {
                .directory-icon-text {
                  text-decoration: underline;
                }
              }
            }
            .directory-btn-link {
              text-transform: capitalize;
              padding: 10px 15px;
              font-weight: 500 !important;
              .has-mobile-nav & {
                width: 200px;
              }
            }
            &.logo-teams, &.logo-tickets {
              width: 70px;
              padding: 0px;
              .has-mobile-nav & {
                min-height: 70px;
              }
            }
            &.team-name-tickets, &.team-name-teams {
              font-size: 20px;
              font-family: $fontOne;
              color: $link-color;
              width: 165px;
              text-transform: uppercase;
              font-weight: 700;
              line-height: 1;
              .has-mobile-nav & {
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
  #user_filters_form {
    padding-bottom: 20px;
  }
}
